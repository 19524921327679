import React from 'react'

import {
  Bullet,
  Button,
  Buttons,
  Columns,
  Divider,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  List,
  ListItem,
  Main,
  Message,
  Paragraph,
  QuestionCircleIcon,
  Spacer,
  Textarea,
  ThumbDownIcon,
  ThumbUpIcon,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { NoteItem } from '../../components/NoteItem'
import { PatternExampleTallennaLuonnoksena } from '../../examples/react/PatternExampleTallennaLuonnoksena'
import { PatternExampleFailTallennaLuonnoksena } from '../../examples/react/PatternExampleToimintoFail'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Tallenna luonnoksena -toiminto">
    <Playground
      enableOverflow
      example={PatternExampleTallennaLuonnoksena}
    />
    <Section title="Käyttötarkoitukset">
      <Message attentionColor="success">
        <Heading
          icon={
            <ThumbUpIcon
              color="success"
              size="lg"
            />
          }
          level={3}
          size={5}
        >
          Käytetään
        </Heading>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <strong>Lomakkeilla</strong>, lisätoimintona kun lomakkeen
            päätoiminto on <Link page="Lähetä-toiminto" /> tai{' '}
            <Link page="Julkaise-toiminto" /> (tarvittaessa myös{' '}
            <Link page="Tallenna-toiminto" />
            ).
          </ListItem>
          <ListItem>
            <strong>Lomakkeilla</strong>,{' '}
            <Link page="Jatka myöhemmin -toiminto" /> sijasta, jos keskeneräisiä
            tietoja validoidaan käyttäjän poistuessa lomakkeelta (esim.
            pakollisia kenttiä).
          </ListItem>
        </List>
      </Message>
      <Message attentionColor="danger">
        <Heading
          icon={
            <ThumbDownIcon
              color="danger"
              size="lg"
            />
          }
          level={3}
          size={5}
        >
          Ei käytetä
        </Heading>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <strong>Lomakkeilla</strong>, joilla on automaattitallennus. Tällöin
            käytössä <Link page="Jatka myöhemmin -toiminto" />
          </ListItem>
          <ListItem>
            <strong>Katselunäkymissä</strong>
          </ListItem>
          <ListItem>
            <strong>Käsittelynäkymissä</strong>
          </ListItem>
        </List>
      </Message>
    </Section>
    <Section title="Periaatteet">
      <Paragraph noMargin>
        Toiminnallisuudeltaan vastaava kuin <Link page="Tallenna-toiminto" />,
        mutta tallennettaville tiedoille annetaan tietokannassa tilaksi Luonnos.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Luonnoksen tallentamisen vaatimuksena voi olla nimen antaminen
          tallenettavalle tietueelle.
        </ListItem>
        <ListItem>
          Jos nimi ei ole luonnokselle pakollinen tieto, tallennettavan
          luonnoksen nimeksi annetaan &quot;Luonnos N&quot;. N =
          Järjestysnumero, joka määräytyy käyttäjän tallentamien luonnosten
          määrän perusteella.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Tietojen muokkaaminen: kyllä
      </Heading>
      <List variant="unordered">
        <ListItem>
          <strong>Käyttäjä voi muokata luonnosta</strong> Muokkaa-toiminnolla.
        </ListItem>
        <ListItem>
          Vanhentuneiden tietojen poistamiselle voidaan tarvittaessa tarjota{' '}
          Poista-toiminto.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="warning"
            icon={<QuestionCircleIcon color="white" />}
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Tietojen validointi: tarvittaessa
      </Heading>
      <List variant="unordered">
        <ListItem>
          Lomakkeella syötetyt tiedot <strong>validoidaan tarvittaessa</strong>{' '}
          toiminnon suorittamisen yhteydessä.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Esimerkiksi tiettyjen pakollisten tietojen kuten nimen täyttämistä
              voidaan vaatia ennen luonnoksen tallentamista.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Validointivirheistä ilmoitetaan käyttäjälle{' '}
          <Link page="Lomakkeen virheenkäsittely" /> -patternin mukaisesti
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Toiminnon varmistus: kyllä
      </Heading>
      <List variant="unordered">
        <ListItem>
          Toiminnon suorittaminen <strong>varmistetaan aina</strong> käyttäjältä
          varmistusdialogilla (
          <Code>
            <Link page="Modal" />
          </Code>
          )
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon onnistumisesta: kyllä
      </Heading>
      <List variant="unordered">
        <ListItem>
          Toiminnon onnistumisesta <strong>ilmoitetaan aina</strong> käyttäjälle
          väliaikaisella ilmoituksella (
          <Code>
            <Link page="Notifications" />, status='success'
          </Code>
          )
        </ListItem>
        <ListItem>
          Viesti voi olla geneerinen &quot;Luonnos tallennettiin
          onnistuneesti&quot;, tarkentaa tallennetun tiedon tyypin tai tarjota
          tarkempaa kontekstikohtaista tietoa.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon epäonnistumisesta: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Toiminnon epäonnistumisesta <strong>ilmoitetaan aina</strong>{' '}
          käyttäjälle
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <strong>
                Tallentamisen yhteydessä ilmenneestä validointivirheestä
              </strong>{' '}
              ilmoitetaan näkymään lisättävällä varoituksella (
              <Code>
                <Link page="Alert" />
              </Code>
              ). Tarkempi ohjeistus <Link page="Lomakkeen virheenkäsittely" />{' '}
              -patternissa
            </ListItem>
            <ListItem>
              <strong>
                Tallentamisen estävästä välittömästä järjestelmävirheestä
              </strong>{' '}
              ilmoitetaan varoitusdialogilla (
              <Code>
                <Link page="Modal" />
              </Code>
              ). Katso esimerkki kohdasta{' '}
              <a href="#tilat-ja-muunnelmat">Tilat ja muunnelmat</a>.
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Divider />
      <Heading
        level={3}
        size={5}
      >
        Liittyvät toiminnot
      </Heading>
      <List variant="unordered">
        <ListItem>
          Toimintoa käytetään lisätoimintona kun lomakkeen päätoiminto on{' '}
          <Link page="Lähetä-toiminto" /> tai <Link page="Julkaise-toiminto" />.
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Playground
        enableOverflow
        example={PatternExampleTallennaLuonnoksena}
      />
      <NoteItem
        bullet="1"
        title={
          <>
            Painike (
            <Code>
              <Link page="Button" /> color='primary' variant='outline'
            </Code>
            )
          </>
        }
      >
        <Paragraph>
          Painikkeesta avautuu varmistusdialogi josta käyttäjä hyväksyy tai
          peruuttaa toiminnnon suorittamisen.
        </Paragraph>
      </NoteItem>
      <NoteItem
        bullet="2"
        title={
          <>
            Varmistusdialogi (
            <Code>
              <Link page="Modal" />
            </Code>
            )
          </>
        }
      >
        <List variant="unordered">
          <ListItem>
            <strong>Otsikko</strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Varmistus toiminnon suorittamisesta kysymysmuodossa.
              </ListItem>
              <ListItem>
                Oletuksena kysymyksessä täsmennetään tallennettavan tiedon
                tyyppi. Esimerkiksi: &quot;Tallennetaanko työpaikkailmoitus
                luonnoksena?&quot;
              </ListItem>
              <ListItem>
                Geneeristä muotoilua &quot;Tallennetaanko tiedot
                luonnoksena?&quot; käytetään, jos tiedon tyyppiä ei ole
                mahdollista täsmentää.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Teksti</strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Tarkentavaa lisätietoa toiminnon hyväksymisen yksityiskohdista
                ja seurauksista.
              </ListItem>
              <ListItem>
                Oletuksena tekstissä kerrotaan &quot;Voit jatkaa tallennetun
                luonnoksen muokkaamista myöhemmin.&quot;
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Toiminnot</strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                <strong>Peruuta</strong>-painike (
                <Code>
                  <Link page="Button" />, variant='outline'
                </Code>
                ).
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>
                    Sulkee dialogin ja palauttaa käyttäjän näkymään jossa hän
                    painoi toimintopainiketta.
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <strong>Lähetä</strong>-painike (
                <Code>
                  <Link page="Button" />, color='secondary'
                </Code>
                ).
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>sulkee dialogin ja suorittaa toiminnon</ListItem>
                  <ListItem>
                    Käyttäjä ohjataan näkymään, jossa luonnokseksi tallennettu
                    tietue on käyttäjän nähtävissä ja avattavissa uudelleen
                    muokattavaksi.
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="3"
        title={
          <>
            Ilmoitus toiminnon onnistumisesta (
            <Code>
              <Link page="Notifications" />, status='success'
            </Code>
            )
          </>
        }
      >
        <Paragraph noMargin>Ilmoituksen teksti voi olla</Paragraph>
        <List variant="unordered">
          <ListItem>
            geneerinen &quot;Luonnos tallennettiin onnistuneesti&quot;,
          </ListItem>
          <ListItem>tarkentaa tallennetun tiedon tyypin tai</ListItem>
          <ListItem>
            tarjota lisäksi tarkempaa kontekstikohtaista tietoa, kuten
            tallennetun luonnoksen otsikon.
          </ListItem>
        </List>
      </NoteItem>
    </Section>
    <Section title="Tilat ja muunnelmat">
      <SectionHeading>Ilmoitus järjestelmävirheestä</SectionHeading>
      <Paragraph noMargin>
        Tallentamisen estävästä välittömästä järjestelmävirheestä ilmoitetaan
        varoitusdialogilla (
        <Code>
          <Link page="Modal" />
        </Code>
        ).
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Modaalin otsikko ja teksti voivat olla esimerkin mukaisesti
          geneerisiä, tai antaa tarkempaa tietoa epäonnistumisen syystä.
        </ListItem>
        <ListItem>
          Sulje-toiminto palauttaa käyttäjän näkymään jossa hän painoi
          toimintopainiketta.
        </ListItem>
      </List>
      <Playground enableOverflow>
        {PatternExampleFailTallennaLuonnoksena()}
      </Playground>
    </Section>
    <Section title="Sijoittuminen näkymässä">
      <List variant="unordered">
        <ListItem>
          Painike sijoitetaan aina tiedot lähettävän toimintopainikkeen
          välittömään läheisyyteen.
        </ListItem>
        <ListItem>
          Sijoittelusta lomakkeella tarkemmin{' '}
          <Link page="Lomakkeen toiminnot" /> -patternissa.
        </ListItem>
      </List>
      <ZoomedExample>
        <Columns layout="single">
          <Main>
            <FormLayout>
              <FormLayoutItem>
                <FormGroup noMargin>
                  <Textarea label="Lomakekenttä" />
                </FormGroup>
              </FormLayoutItem>
            </FormLayout>
            <Spacer marginTop="xl">
              <Buttons align="center">
                <Button
                  iconLeft={<XIcon />}
                  variant="plain"
                >
                  Keskeytä
                </Button>
                <Button
                  color="primary"
                  variant="outline"
                >
                  Tallenna luonnoksena
                </Button>
                <Button color="secondary">Julkaise</Button>
              </Buttons>
            </Spacer>
          </Main>
        </Columns>
      </ZoomedExample>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>Työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Työnantaja-asiakas: Työpaikkailmoituksen luonti- ja muokkauslomake
        </ListItem>
      </List>
      <SectionHeading>Asiantuntijan työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>Palvelutarvearvion luonti- ja muokkauslomake</ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
